import React, {useState} from "react"
import { GlobalStyles } from "twin.macro"
import Player from "../components/player"
import PlayerContext from '../contexts/player';

export default function Layout({ children }) {
  const [episode, setEpisode] = useState()
  return (
    <PlayerContext.Provider value={{episode, setEpisode}}>
      <GlobalStyles />
        {children}
      <Player />
    </PlayerContext.Provider>
  )
}
