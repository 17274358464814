import React from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import tw from "twin.macro"
import PlayerContext from '../contexts/player';

const Wrapper = tw.div`
fixed bottom-0 z-10 bg-white w-96
`
const Player = props => {
    const {episode} = React.useContext(PlayerContext);
    return (
        <Wrapper>
            {episode && (
                <AudioPlayer
                    autoPlay
                    src={episode}
                />
            )}
        </Wrapper>
    )
}

export default Player
